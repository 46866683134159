import React from 'react';

import "./index.scss";

const Plug = () => {
    return (
        <div className="plug">
            <div className="plug__content plug__content--pc">
                Акцію завершено.<br /><br />
                Інформація про картку за посиланням: <b><a href="https://verycard.tascombank.ua/?utm_source=landing_millennium&utm_medium=link">ПРО&nbsp;VERY&nbsp;CARD</a></b>
            </div>
            <div className="plug__content plug__content--mobile">
                Акцію завершено.<br /><br />
                Інформація про картку <br />за посиланням: <br /><b><a href="https://verycard.tascombank.ua/?utm_source=landing_millennium&utm_medium=link">ПРО&nbsp;VERY&nbsp;CARD</a></b>
            </div>
        </div>
    )
}

export default Plug;
