import $api from '../http';

interface REQUEST_OTP_DATA {
    login: string,
    source: string,
}

interface VALIDATE_OTP_DATA {
    login: string,
    otp: string
}

interface CREATE_JOURNAL_REQUEST {
    phone: string,
    source: string,
    product: number
}

export default class Services {

    static request_otp = async (data: REQUEST_OTP_DATA) => {
        return await $api.post("/agent/otp/send", data)
    }

    static valid_otp = async (data: VALIDATE_OTP_DATA) => {
        return await $api.post("/agent/otp/verify", data)
    }

    static create_card_request = async(data: CREATE_JOURNAL_REQUEST) => {
        return await $api.post("/journal/verycard-millennium", data);
    }

}