/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Services from '../../services';

type State = {
    ref_id: string,
    otp: string,
    loading: boolean,
    error: boolean,
    errorStatus: string,
    step: string,
    is_bot: boolean
}

const initialState: State = {
    ref_id: "",
    otp: "",
    loading: false,
    error: false,
    errorStatus: "",
    step: "init",
   //step: "success",
    is_bot: false
}

export const requestOtp = createAsyncThunk<any, any>('index/requestOtp', async (data: any) => {
    
    const res: any = await Services.request_otp({...data, source: "verycard-millennium"}).then(res => res.data).catch((err) => {

        if(err.response.data.status === "LIMIT_OF_RESEND") {
            return {
                status: "LIMIT_OF_RESEND",
            }
        }

        return {
            status: "SYSTEM_ERROR",
        }
    });
    
    return res;
    
})

export const valieOtp = createAsyncThunk<any, any>('index/valieOtp', async (data: any, thunkAPI: any) => {
    
    const res: any = await Services.valid_otp(data).then(res => res.data).catch(err => {
        if(err.response.data.status === "FAIL") {
            return {
                status: "FAIL",
            }
        }
    });

    if(res.status === "OK") {
        thunkAPI.dispatch(milleniumRequest({phone: data.login, source: 'millennium'}));

        return res;
    }

    return res;
    
})

export const milleniumRequest = createAsyncThunk<any, any>('index/milleniumRequest', async (data: any) => {
    
    const res: any = await Services.create_card_request({
        ...data,
        product: 108
    }).then(res => res.data).catch(err => err);

    return res;
    
})

export const indexSlice = createSlice({
    name: "index",
    initialState,
    reducers: {
        setPhone(state: any, action: any) {
            state.phone = action.payload
        },
        setIsBot(state: any, action: any) {
            state.is_bot = action.payload;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(requestOtp.pending, (state: any) => {
            state.loading = true;
            state.error = false;
        }),
        builder.addCase(requestOtp.fulfilled, (state: any, action: any) => {
            if(action.payload.status === "OTP_IS_SENT") {
                state.ref_id = action.payload.ref_id;
                state.step = "otp";     
                state.loading = false;
            } else if(action.payload.status === "LIMIT_OF_RESEND") {
                state.error = true;
                state.errorStatus = "LIMIT_OF_RESEND";
                state.loading = false;
            } else if(action.payload.status === "JOURNAL_ALREADY_EXIST_ERROR") {
                if(action.payload.client) {
                    state.loading = false;
                    state.error = true;
                    state.errorStatus = "IS_CLIENT";
                    state.step = "init"
                } else {
                    state.step = "success"
                    state.loading = false;
                }
            } else {
                state.error = true;
                state.errorStatus = "SYSTEM_ERROR";
                state.loading = false;
            }
        })
        builder.addCase(requestOtp.rejected, (state: any, action: any) => {
            console.log("requestOtp.rejected", action);
            state.error = true;
            state.errorStatus = action.payload.status;
            state.loading = false;
        })

        builder.addCase(valieOtp.pending, (state: any) => {
            state.loading = true;
            state.error = false;
        }),
        builder.addCase(valieOtp.fulfilled, (state: any, action: any) => {
            if(action.payload.status === "FAIL") {
                state.error = true;
                state.errorStatus = action.payload.status;
            }
            state.loading = false;
        })
        builder.addCase(valieOtp.rejected, (state: any, action: any) => {
            state.error = true;
            state.errorStatus = action.payload.status;
            state.loading = false;
        })

        builder.addCase(milleniumRequest.pending, (state: any) => {
            state.loading = true;
            state.error = false;
        }),
        builder.addCase(milleniumRequest.fulfilled, (state: any, action: any) => {
            if(action.payload.status === "OK") {
                state.step = "success";
                state.loading = false;
            } else {
                state.error = true;
                state.errorStatus = action.payload.status;
                state.loading = false;
                state.step = "init";
            }
            
        })
        builder.addCase(milleniumRequest.rejected, (state: any, action: any) => {
            console.log("milleniumRequest.rejected", action);
            state.error = true;
            state.errorStatus = action.payload.status;
            state.loading = false;
        })
    }
})

export const { setPhone, setIsBot } = indexSlice.actions
