import React from 'react';
import {Provider} from 'react-redux';
import {store} from './redux/store';
import { ToastContainer} from 'react-toastify';

// import MainPage from './pages/Main';
import Header from './parts/Header';
import Plug from './containers/Plug';

import "./styles/app.scss";

function App() {

  const [width, setWidth] = React.useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  React.useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;

  return (
    <Provider store={store}>
      <React.Fragment>
        <Header isPlug={!isMobile} />
        {/* {isMobile ? <MainPage /> : <Plug />} */}
        {<Plug />}
        <ToastContainer />
      </React.Fragment>
    </Provider>
  );
}

export default App;
