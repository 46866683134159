import React from 'react';

import MasterCard from '../../assets/images/mastercard.svg';
import TASLogo from '../../assets/images/TAS.svg';

import "./index.scss";

const Header = (props: any) => {

    const {isPlug = false} = props;

    return (
        <header className={isPlug ? "header header--fixed" : "header"}>
            <div className='header__wrap'>
                <img src={MasterCard} alt="Master Card" />
                <div className='divider'></div>
                <img src={TASLogo} alt="TAS Logo" />
            </div>
        </header>
    )
}

export default Header;